.workCard {
  max-width: 400px;
  //   height: 350px;
  height: 250px;
  position: relative;
  display: block;
  overflow: hidden;
  color: #fff;
  border-radius: 0.8rem;
  border: 1px solid rgb(212, 212, 212);

  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(148, 178, 235, 0.2);
  }

  img {
    width: 100%;
    height: auto;
    transition: transform 0.35s linear;
  }
  .card-caption {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(32, 39, 52, 0.8);
    opacity: 0;
    transition: opacity 0.35s linear 0s;
  }
  .card-caption-inner {
    position: absolute;
    top: 35%;
    right: 0;
    bottom: 0;
    left: 0;

    text-align: center;
  }
  .card-title {
    font-size: 2.2rem;
    line-height: 3.2rem;
    letter-spacing: 0.18rem;
  }
  .card-cat {
    font-size: 1.15rem;
    color: #bdbdbd;
  }

  a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &:hover .card-caption {
    opacity: 1;
  }
  &:hover img {
    transform: scale(1.1);
  }
}
