.timeline {
  .timeline-item {
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
    padding-bottom: 15px;
  }
  // Left Part
  .left-part {
    width: 30%;
    display: table-cell;
    padding-right: 25px;
    min-height: 100%;
    text-align: right;
    vertical-align: top;
    .item-period {
      margin: 3px 0;
      font-size: 14px;
      line-height: 1.4em;
    }
    .item-company {
      display: block;
      margin: 0 0 4px;
      font-size: 13px;
      font-weight: 300;
      line-height: 1.45em;
      color: #9baab3;
    }
  }
  // divider
  .divider {
    position: absolute;
    top: 0;
    left: 30%;
    bottom: 0;
    width: 1px;
    background-color: #eee;

    &::before {
      content: '';
      display: block;
      margin-top: 2px;
      width: 21px;
      height: 21px;
      position: absolute;
      margin-left: -10px;
      border-radius: 12px;
      background-color: #0099e5;
      opacity: 0.25;
      z-index: 0;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      margin-top: 6px;
      width: 13px;
      height: 13px;
      margin-left: -6px;
      background-color: #fff;
      border-radius: 10px;
      border: 2px solid #0099e5;
      z-index: 1;
    }
  }
  // Right part
  .right-part {
    width: 70%;
    display: table-cell;
    padding-left: 25px;
    padding-right: 15px;
    vertical-align: top;
    .item-title {
      font-size: 1.2rem;
      margin-bottom: 3px;
      font-weight: 600;
    }
  }
}

.clearfix {
  &::before {
    content: ' ';
    display: table;
  }
}
