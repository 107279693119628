.heading {
  .bg-text {
    color: #dee3e4;
    opacity: 0.4;
    font-size: calc(1.5rem + 4vw);
    font-weight: 600;
    letter-spacing: 8px;
    @media (min-width: 992px) {
      font-size: 4.5rem;
    }
  }
  .ft-text {
    font-size: calc(0.5rem + 2.5vw);
    font-weight: 700;
    @media (min-width: 992px) {
      font-size: 2rem;
    }
  }
  .ft-border {
    width: 80px;
  }
}
