.form {
  background-color: hsla(0, 0%, 55%, 0.027);
  padding: 2rem 0;
  .form-box {
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0.5px 0.5px 6px 1px rgb(201, 201, 201);
    .btn-style {
      color: #fff;
    }
  }
}
