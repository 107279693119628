.modal-body {
  img {
    width: 100%;
  }
  .modal-body--left {
    @media (max-width: 576px) {
      margin-bottom: 1.5rem;
    }
  }
  .title {
    font-size: 1.3rem;
    font-weight: 600;
  }
}
.model-details {
  .title {
    font-size: 1.3rem;
    font-weight: 600;
  }
  ul {
    padding: 0;
    li {
      font-size: 1.1rem;
      list-style: none;
      line-height: 2.2rem;
      padding-bottom: 0.5rem;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        border-bottom: 1px solid gray;
        width: 100%;
      }
      &:nth-child(n + 2) {
        padding-top: 0.5rem;
      }

      .tags {
        display: inline-block;
        margin-left: 5px;
        span {
          margin-right: 5px;
        }
      }
      .url {
        text-decoration: none;
        margin-left: 5px;
      }
    }
  }
}
