.skill {
  h4 {
    font-size: 13px;
    font-weight: 600;
    line-height: 1.1em;
    position: relative;
    float: left;
    margin: 0 0 4px;
  }
  .skill-value {
    font-size: 11px;
    line-height: 1.1em;
    position: relative;
    float: right;
    margin: 0 0 4px;
    color: #91a5b3;
  }
}

.skill-container {
  position: relative;
  display: inline-block;
  background-color: #e0e3e7;
  box-sizing: border-box;
  height: 6px;
  border-radius: 8px;
  margin-bottom: 8px;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    background-color: #0099e5;
  }
  .skill-percentage {
    background-color: #0099e5;
    border-radius: 9px;
    height: 6px;
    padding: 0;
  }
}
