.education {
  .certificate-section {
    margin-top: 2.8rem;
  }
  .certificates {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 22px;
    align-items: center;
    justify-content: center;
  }
}
